import naughtyWords from 'naughty-words'
import badWordsDictionary from '@/bad-words-dictionary'

export default {
    hasBadWords(string) {
        if (!string) {
            return false
        }
        const badWordsDictionaryArray = Object.values(badWordsDictionary)
        let languagesArray = Object.values(naughtyWords)
        languagesArray = languagesArray.concat(badWordsDictionaryArray)
        return languagesArray.some(language => language.some(word => string.toLowerCase().includes(word)))
    }
}
